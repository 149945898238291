import React from "react";
import AppState from "../types/AppState";

export const ConsentHeader = ({
	state,
	userName,
}: {
	state: AppState;
	userName: string;
}) => {
	return (
		<>
			<p className="mt-6 mb-3 flex justify-center">
				<img
					src={state.partnerLogoUrl}
					alt={state.clientName}
					className="w-32"
				/>
			</p>
			<p className="text-3xl text-lic-darkblue leading-10">
				<span className="text-lic-blue">{state.clientName}</span> wants
				to access your MINDA account
			</p>
			<p className="my-3 text-lic-darkblue">{userName}</p>
			<hr className="mb-3" />
		</>
	);
};
export default ConsentHeader;
