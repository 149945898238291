import React, { Dispatch, SetStateAction } from "react";

const HerdSelector = ({
	setSelectedHerds,
	herds,
}: {
	setSelectedHerds: Dispatch<SetStateAction<string[]>>;
	herds: string[] | undefined;
}) => {
	if (herds === undefined || herds.length === undefined) {
		// herds not yet initialised
		return null;
	}
	const toggleHerdSelection = (herd: string) => {
		setSelectedHerds((current) => [...current, herd]);
	};

	// Fix for tailwind border not showing
	// see: https://v1.tailwindcss.com/docs/preflight
	const style : React.CSSProperties = {
		borderWidth: 1,
		borderStyle: "border-solid",
		borderColor: "border-sky-200"
	};

    const listItems = herds.map((herd: string) => (
        <option key={herd} value={herd}>{herd}</option>
    ));

    return (<div id="herdSelector" className="grid grid-cols-2 sm:grid-cols-3" >
        <select
			onChange={(event) => toggleHerdSelection(event.target.value)}
			defaultValue=""
			className="rounded p-1 border-sky-200 border-solid border-1"
			style={style}
			>
		<option hidden disabled value=""> -- Select herd -- </option>
        {listItems}
        </select>
    </div>);
};

export default HerdSelector;