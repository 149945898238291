import React from "react";
import Footer from "./Footer";

const Forbidden = () => {
	return (
		<div className="box-border shadow border-2 h-auto mx-auto my-20 max-w-xl">
			<div className="mx-24 mt-10 h-4/5 py-10">
				<div>
					<img src={"LIC-Logo1024-286.png"} alt="LIC" className="h-5" />
				</div>
				<hr className="m-3" />
				<div className="pt-10 text-4xl">Access denied</div>
				<div className="mt-12 mb-32">
					<div>
						Sorry, it seems you don't have permission to access this
						page.
					</div>
					<div>
						You can
						<span id="linkButton" className="text-lic-darksky mx-1 cursor-pointer" onClick={() => window.history.back()}>
							go back
						</span>
						to previous page and try again later.
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
export default Forbidden;
