import React from "react";

const Spinner = (props: { isBusy: boolean }) => {
	if (!props.isBusy) {
		return null;
	}
	return (
		<div className="flex justify-center transform translate-x-1/5 translate-y-1/5 ">
			<div className="border-t-transparent border-solid animate-spin  rounded-full border-lic-blue border-8 h-8 w-8"></div>
		</div>
	);
};

export default Spinner;
