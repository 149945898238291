import React from "react";

const TermsAndConditions = (props: { clientName: string }) => {
	return (
		<div id="termsAgreeText" className="mt-3 text-xs h-[100px] overflow-y-auto leading-normal">
			<p>
				By completing this form, You accept and acknowledge that You
				have authority (or are authorised by the legal owner(s) of the
				animals for Participant Code(s) selected above) and give LIC
				permission to provide animal data (including core data as
				defined by the Dairy Industry Restructuring Act 2001) to{" "}
				{props.clientName}, and for LIC to receive data provided by You
				to {props.clientName}.
			</p>
			<p className="mt-4">
				It is acknowledged that this permission will remain applicable
				to all animal data supplied until such time permission is
				revoked by You.
			</p>
			<p className="mt-4">
				In addition to these terms and conditions, all products and
				services supplied by LIC are supplied subject to the Conditions
				and Services Rules applicable from time to time, a copy of which
				may be obtained at the following link:
				<a
					href="https://www.lic.co.nz/about/lic-service-rules/"
					target="_blank"
					className="p-1 text-lic-darksky cursor-pointer"
				>
					https://www.lic.co.nz/about/lic-service-rules
				</a>
				. If there are any inconsistencies between these terms and
				conditions and the Conditions and Service Rules, the Conditions
				and Service Rules shall prevail.
			</p>
		</div>
	);
};

export default TermsAndConditions;
