import React from "react";

export const ErrorMessage = ({ showError }: { showError: boolean }) => {
	if (!showError) {
		return null;
	}

	return (
		<div className="m-10">
			<p className="mb-3">Sorry, something went wrong.</p>
			<div>Please try again later.</div>
		</div>
	);
};
export default ErrorMessage;
