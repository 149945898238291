import React from "react";
import { Dispatch, SetStateAction } from "react";
import HerdList from "./HerdList";
import TermsAndConditions from "./TermsAndConditions";
import HerdSelector from "./HerdSelector";

const HerdListAndTermsContainer = ({
	selectedHerds,
	setSelectedHerds,
	herds,
	consentedHerds,
	clientName,
	termsAgreedChanged,
	singleSelection
}: {
	selectedHerds: string[];
	setSelectedHerds: Dispatch<SetStateAction<string[]>>;
	herds: string[] | undefined;
	consentedHerds: string[];
	clientName: string;
	termsAgreedChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
	singleSelection: boolean;
}) => {
	if (herds === undefined) {
		// herds not yet initialised
		return null;
	}

	if (herds.length < 1) {
		// if the user has no permission to herds, assume that they are not the farm owner
		return (
			<div className="bg-lic-dangerpink rounded-lg text-lic-dangerred grid grid-cols-12 px-2 py-3 mb-5">
				<img
					src={"exclamation.png"}
					alt="!"
					className="w-6 h-6 col-span-1 col-start-1 mx-auto"
				/>
				<span className="col-span-11 text-left leading-4">
					You do not have permission to provide consent. You must be a herd owner or delegated authority to provide consent to share data.
				</span>
			</div>
		);
	}

	return (
		<>
			{consentedHerds.length > 0 && (
				<div
					className={
						"grid text-left py-2 min-h-0 max-h-52 overflow-y-auto"
					}
				>
					<p className="mb-3">
						{clientName} has already been granted access to your
						animal data for these herds:
					</p>
					<HerdList
						herds={consentedHerds}
						selectedHerds={[]}
						consentedHerds={consentedHerds}
						// eslint-disable-next-line @typescript-eslint/no-empty-function
						setSelectedHerds={() => {}}
					/>
				</div>
			)}
			{herds.length > consentedHerds.length && (
				<div
					className={
						"grid text-left py-2 min-h-0 max-h-52 overflow-y-auto"
					}
				>
				{singleSelection ?
				(
					<p className="mb-3">
						To allow {clientName} to access animal data {consentedHerds.length ? " for additional herds" : ""},
						select the participant code from the list below:
					</p>) :
				(
					<p className="mb-3">
						Allow {clientName} to{" "}
						{consentedHerds.length ? "additionally" : ""} access
						animal data for these herds:
					</p>
				)}
				{singleSelection ? ( 
					<HerdSelector
						herds={herds.filter(
							(herd) => !consentedHerds.includes(herd)
						)}
						setSelectedHerds={setSelectedHerds}
					/>
				) : (
					<HerdList
						herds={herds.filter(
							(herd) => !consentedHerds.includes(herd)
						)}
						selectedHerds={selectedHerds}
						consentedHerds={[]}
						setSelectedHerds={setSelectedHerds}
					/>
				)}
				</div>
			)}
			<div className="text-left">
				<TermsAndConditions clientName={clientName} />
				<form className="needs-validation" noValidate>
					<div className="mt-5">
						<div>
							<input
								type="checkbox"
								id="termsAgreeCheck"
								name="termsAgree"
								required
								onChange={termsAgreedChanged}
								data-testid="cbxAgreeTerms"
							/>
							<label className="mx-3" htmlFor="termsAgreeCheck">
								I have read and agree to the above.
							</label>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default HerdListAndTermsContainer;
