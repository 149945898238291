import React, { Dispatch, SetStateAction } from "react";

const HerdList = ({
	selectedHerds,
	setSelectedHerds,
	consentedHerds,
	herds,
}: {
	selectedHerds: string[];
	setSelectedHerds: Dispatch<SetStateAction<string[]>>;
	herds: string[] | undefined;
	consentedHerds: string[];
}) => {
	const toggleHerdSelection = (herd: string) => {
		if (!selectedHerds.includes(herd)) {
			setSelectedHerds((current) => [...current, herd]);
		} else {
			setSelectedHerds((current: string[]) =>
				current.filter((item: string) => item !== herd)
			);
		}
	};

	if (herds === undefined || herds.length === undefined) {
		// herds not yet initialised
		return null;
	}

	const listItems = herds.map((herd: string) => (
		<div key={`herdItemContainer${herd}`} className="px-8">
			<input
				type="checkbox"
				className="mx-2 mt-3"
				id={`id${herd}`}
				name={`name${herd}`}
				value={herd}
				onChange={() => toggleHerdSelection(herd)}
				data-testid="chxHerd"
				// disable toggle for previously consented herds as there is no functionality/API to revoke consent
				disabled={consentedHerds.includes(herd)}
				checked={
					// a herd in either of these lists should be checked
					consentedHerds.includes(herd) || // previously consented. note these will also be disabled
					selectedHerds.includes(herd) // selected in the current session
				}
			/>
			<label
				htmlFor={`id${herd}`}
				className={
					consentedHerds.includes(herd) ? "text-gray-500" : ""
				}
			>
				{herd}
			</label>
		</div>
	));
	return <div className="grid grid-cols-2 sm:grid-cols-3">{listItems}</div>;
};

export default HerdList;
