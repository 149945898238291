import React from "react";

const Footer = () => {
	return (
		<div className="text-center text-xs m-auto py-2">
			<hr className="mb-3 mt-2" />
			<div>
				<p>© 2022 Copyright LIC • All rights reserved</p>
				<p className="my-4">
					<a
						href="https://minda.lic.co.nz/privacy/"
						target="_blank"
						className="p-0 text-lic-darksky cursor-pointer"
					>
						Privacy
					</a>
					<a
						href="https://minda.lic.co.nz/terms/"
						target="_blank"
						className="p-1 text-lic-darksky cursor-pointer"
					>
						Terms of use
					</a>
				</p>
			</div>
		</div>
	);
};

export default Footer;
