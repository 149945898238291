import { ParsedQuery } from "query-string";
import { useEffect, useState } from "react";
import queryString from "query-string";
import AppState from "../types/AppState";

const appStateKey = "app_state";

export const defaultAppState: AppState = {
	originalState: "",
	partnerId: "",
	partnerLogoUrl: "Noimagefile.png",
	clientName: "",
	singleSelection: false,
};

const useLocalStorageState = () => {
	const [state, setState] = useState<AppState>(defaultAppState);

	useEffect(() => {
		const queryParams = queryString.parse(location?.search);
		if (
			(!queryParams.state ||
				!queryParams.partnerID ||
				!queryParams.clientName) &&
			!queryParams.code
		) {
			clear();
		} else if (
			queryParams.state &&
			queryParams.partnerID &&
			queryParams.clientName
		) {
			// Local storage used to maintain the state and return to auth0 continue end point
			setItems(queryParams);
		} else {
			setState(getLocalStorageState());
		}
	}, []);

	const setItems = (queryParams: ParsedQuery<string>) => {
		const state = defaultAppState;

		if (queryParams?.state && !Array.isArray(queryParams.state)) {
			state.originalState = queryParams.state;
		}
		if (queryParams?.partnerID && !Array.isArray(queryParams.partnerID)) {
			state.partnerId = queryParams.partnerID;
		}
		if (queryParams?.clientName && !Array.isArray(queryParams.clientName)) {
			state.clientName = queryParams.clientName;
		}
		if (
			queryParams?.partnerLogoUrl &&
			!Array.isArray(queryParams.partnerLogoUrl)
		) {
			state.partnerLogoUrl = queryParams.partnerLogoUrl;
		}
		if (queryParams?.singleSelection && !Array.isArray(queryParams.singleSelection)) {
			state.singleSelection = queryParams.singleSelection === "true";
		}
		localStorage.setItem(appStateKey, JSON.stringify(state));
		setState(state);
	};

	const clear = () => {
		localStorage.removeItem(appStateKey);
		setState(defaultAppState);
	};

	const getLocalStorageState = (): AppState => {
		const appStateString = localStorage.getItem(appStateKey);
		return appStateString ? JSON.parse(appStateString) : defaultAppState;
	};

	return [state];
};

export default useLocalStorageState;
