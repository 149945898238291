import queryString from "query-string";
import { JwtPayloadExt } from "../types/JwtPayloadExt";

const scope = "read:herds write:consent read:consent";

export default class Auth0Helper {
	// Gets a token from auth0 with scope to read herds and write to consent
	static getToken = async (authCode: string): Promise<string | undefined> => {
		// early return on no code
		if (!authCode) {
			return "";
		}
		return fetch(`${process.env.REACT_APP_AUTH0_DOMAIN_FULL}/oauth/token`, {
			method: "POST",
			headers: { "content-type": "application/json" },
			body: JSON.stringify({
				grant_type: "authorization_code",
				client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
				client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
				code: authCode,
				redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URI,
			}),
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				return data.access_token ?? "";
			});
	};

	// Navigates to auth0 and then comes back to this page with a code in the url
	static redirectToAuth0 = () => {
		// Get code from Auth0
		const authParams = {
			response_type: "code",
			audience: process.env.REACT_APP_AUTH0_AUDIENCE,
			scope: scope,
			client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
			redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URI,
		};

		window.location.replace(
			`${
				process.env.REACT_APP_AUTH0_DOMAIN_FULL
			}/authorize?${queryString.stringify(authParams)}`
		);
	};

	// On 'Accepting' the T&Cs + herds list, validate with Auth0
	static continueAuth = (state: string) => {
		window.location.assign(
			`${process.env.REACT_APP_AUTH0_DOMAIN_FULL}/continue?state=${state}`
		);
	};

	static isValidToken = (token: JwtPayloadExt) => {
		return (
			token.iss?.includes(
				process.env.REACT_APP_AUTH0_DOMAIN_FULL ?? ""
			) &&
			token.azp === process.env.REACT_APP_AUTH0_CLIENT_ID &&
			token.aud === process.env.REACT_APP_AUTH0_AUDIENCE &&
			this.areAllScopesRequestedIncludedInTokenScope(scope, token.scope)
		);
	};

	static areAllScopesRequestedIncludedInTokenScope = (
		requestedScope: string,
		tokenScope: string
	) => {
		// get rid of leading and trailing spaces before the split
		const requestedScopes = requestedScope.trim().split(" ");
		// by splitting this we get full words to match to and dont have to rely on a part string match
		const tokenScopes = tokenScope.trim().split(" ");
		// every requested scope must be inside the token scopes
		return requestedScopes.every((requestScope) =>
			tokenScopes.includes(requestScope)
		);
	};
}
