import { useState } from "react";
import Spinner from "./Spinner";
import React from "react";
import HerdListAndTermsContainer from "./HerdListAndTermsContainer";
import AppState from "../types/AppState";
import Auth0Helper from "./Auth0Helper";
import CancelConsentMessage from "./CancelConsentMessage";
import ConsentHeader from "./ConsentHeader";
import { writeConsent } from "./Api";

const ConsentContainer = ({
	userName,
	setShowErrorMessage,
	herds,
	consentedHerds,
	accessToken,
	showErrorMessage,
	isLoading,
	state,
}: {
	userName: string;
	setShowErrorMessage: (val: boolean) => void;
	herds: string[] | undefined;
	consentedHerds: string[];
	accessToken: string;
	showErrorMessage: boolean;
	isLoading: boolean;
	state: AppState;
}) => {
	const [termsAgreed, setTermsAgreed] = useState(false);
	const [selectedHerds, setSelectedHerds] = useState<string[]>([]);
	const [isCanceled, setIsCanceled] = useState(false);

	const noHerdsSelected =
		consentedHerds.length === 0 && selectedHerds.length === 0;
	const isDisabled = !termsAgreed || noHerdsSelected;

	const continueAuth = () => {
		Auth0Helper.continueAuth(state.originalState);
	};

	const handleAccept = () => {
		if (!termsAgreed || noHerdsSelected) return;

		writeConsent(
			accessToken,
			userName,
			state.partnerId,
			selectedHerds,
			consentedHerds,
			setShowErrorMessage,
			continueAuth
		);
	};

	const handleCancel = () => {
		setIsCanceled(true);
		setTimeout(continueAuth, 3000);
	};

	const termsAgreedChanged = (e: { target: { checked: boolean } }) => {
		setTermsAgreed(e.target.checked);
	};

	return (
		<div className="text-center">
			<ConsentHeader state={state} userName={userName} />
			{isCanceled ? (
				<CancelConsentMessage clientName={state.clientName} />
			) : (
				<>
					<Spinner isBusy={isLoading} />
					{!showErrorMessage && (
						<>
							{!isLoading && (
								<HerdListAndTermsContainer
									herds={herds}
									selectedHerds={selectedHerds}
									consentedHerds={consentedHerds}
									setSelectedHerds={setSelectedHerds}
									clientName={state.clientName}
									termsAgreedChanged={termsAgreedChanged}
									singleSelection={state.singleSelection}
								/>
							)}

							<div className="flow-root py-5 mt-1">
								<button
									className="w-[124px] float-left bg-transparent hover:text-white hover:bg-lic-darkblue py-[0.375rem] px-[0.75rem] border border-lic-darkblue hover:border-transparent rounded"
									onClick={handleCancel}
									data-testid="btnCancel"
								>
									Cancel
								</button>
								{!isLoading && (
									<button
										className="w-[124px] float-right bg-lic-darkblue text-white hover:bg-lic-blue py-[0.375rem] px-[0.75rem] border rounded"
										disabled={isDisabled}
										style={{
											opacity: isDisabled ? 0.25 : 1,
										}}
										onClick={handleAccept}
									>
										{consentedHerds.length > 0 &&
										selectedHerds.length === 0
											? "Proceed"
											: "Allow"}
									</button>
								)}
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default ConsentContainer;
