import { JwtPayload } from "jwt-decode";

export const licUsername = "https://lic.co.nz/username";
export const licSingleSelection = "https://lic.co.nz/single_selection";

// See https://github.com/auth0/jwt-decode
export interface JwtPayloadExt extends JwtPayload {
	azp: string;
	scope: string;
	"https://lic.co.nz/username": string;
}
