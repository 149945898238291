import React from "react";
import Spinner from "./Spinner";

export const CancelConsentMessage = ({
	clientName,
}: {
	clientName: string;
}) => {
	return (
		<>
			<div>
				You have cancelled the process of giving {clientName} access to
				your cow data in MINDA.
			</div>
			<br />
			<div>Redirecting...</div>
			<div className="my-3 flex justify-center">
				<Spinner isBusy={true} />
			</div>
		</>
	);
};
export default CancelConsentMessage;
