import { useEffect, useState } from "react";
import Footer from "./Footer";
import Forbidden from "./Forbidden";
import ErrorMessage from "./ErrorMessage";
import ConsentContainer from "./ConsentContainer";
import jwt_decode from "jwt-decode";
import { JwtPayloadExt, licUsername } from "../types/JwtPayloadExt";
import { useLocation } from "react-router-dom";
import React from "react";
import queryString from "query-string";
import Auth0Helper from "./Auth0Helper";
import useLocalStorageState from "./useLocalStorageState";
import { getHerdList, getConsentedHerds } from "./Api";

const Home = () => {
	const [userName, setUserName] = useState("");
	const [accessToken, setAccessToken] = useState("");
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [state] = useLocalStorageState();
	const {
		data: herdList,
		isLoading: isHerdListLoading,
		refetch: getHerdListRefetch,
		isError: isHerdListError,
	} = getHerdList(accessToken, userName);

	const {
		data: consentedHerds,
		isLoading: isConsentedHerdsLoading,
		refetch: getConsentedHerdsRefetch,
		isError: isConsentedHerdsError,
	} = getConsentedHerds(accessToken, userName, state.partnerId);

	const location = useLocation();

	const getData = (authCode: string) => {
		try {
			Auth0Helper.getToken(authCode)
				.then((token) => {
					if (token === undefined || token === "") {
						setShowErrorMessage(true);
						console.error("Token not available.");
					} else {
						const decodedToken: JwtPayloadExt = jwt_decode(token);
						if (Auth0Helper.isValidToken(decodedToken)) {
							const name = decodedToken[licUsername];
							setUserName(name);
							setAccessToken(token);

							getHerdListRefetch();
							getConsentedHerdsRefetch();
						} else {
							setShowErrorMessage(true);
							console.error("Invalid token.");
						}
					}
				})
				.catch((e) => {
					setShowErrorMessage(true);
					console.error(e);
				});
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		const queryParams = queryString.parse(location?.search);
		if (
			queryParams.state &&
			queryParams.partnerID &&
			queryParams.clientName
		) {
			Auth0Helper.redirectToAuth0();
		} else if (typeof queryParams.code === "string") {
			if (state.partnerId) {
				getData(queryParams.code);
			}
		}
	}, [state]);

	const queryParams = queryString.parse(location?.search);
	// Show forbidden message if any of the condition is not met
	if (
		(!queryParams.state ||
			!queryParams.partnerID ||
			!queryParams.clientName) &&
		!queryParams.code
	) {
		console.error("Query params do not exist.");
		return <Forbidden />;
	}
	const error = showErrorMessage || isHerdListError || isConsentedHerdsError;

	return (
		<div className="w-[651px] min-h-[350px] rounded shadow-xl border-solid border-lic-lightblue border-2 text-lic-darkblue m-auto p-[20px]">
			<div className="w-auto px-[15px] mx-auto max-w-[720px] ">
				<div className="flex h-6 mb-2">
					<img src={"LIC-Logo1024-286.png"} alt="LIC" />
					<div className="mx-2 mt-1">Sign in with MINDA</div>
				</div>
				<hr className="my-2" />
				<ConsentContainer
					userName={userName}
					herds={error ? undefined : herdList}
					consentedHerds={consentedHerds || []}
					showErrorMessage={showErrorMessage}
					setShowErrorMessage={setShowErrorMessage}
					accessToken={accessToken}
					isLoading={isHerdListLoading || isConsentedHerdsLoading}
					state={state}
				/>
				<ErrorMessage showError={error} />
				<Footer />
			</div>
		</div>
	);
};

export default Home;
