import { useQuery } from "react-query";
import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, {
	retries: 3,
	retryCondition: () => true, // Retry for all errors
	retryDelay: () => {
		return 1000;
	},
});

export const getHerdList = (accessToken: string, userName: string) => {
	return useQuery<string[] | undefined, Error>(
		["getHerds", { accessToken, userName }],
		() => getHerds(accessToken, userName),
		{ retry: false, initialData: undefined }
	);
};

export const getConsentedHerds = (
	accessToken: string,
	userName: string,
	partnerId: string
) => {
	return useQuery<string[], Error>(
		["getConsentedHerds", { accessToken, userName, partnerId }],
		() => fetchConsentedHerds(accessToken, userName, partnerId),
		{ retry: false }
	);
};

// Gets a list of PTPT herd codes that the client can give consent for
const getHerds = (accessToken: string, userName: string) => {
	if (!accessToken || !userName) {
		return undefined; // do not return empty list, as that has error message ramifications
	}
	const reqHeaders = {
		headers: { Authorization: `Bearer ${accessToken}` },
	};
	return axios
		.get(
			`${process.env.REACT_APP_CONSENT_AUTH_API_URI}/consent/herds/${userName}`,
			reqHeaders
		)
		.then((response: { data: { herds: string[] } }) => {
			// This is done since the request returns string instead of array.
			const herdList: string[] | PromiseLike<string[]> =
				response.data.herds ?? [];
			return herdList;
		});
};

const fetchConsentedHerds = (
	accessToken: string,
	userName: string,
	partnerId: string
) => {
	if (!accessToken || !userName || !partnerId) {
		return [];
	}
	const reqHeaders = {
		headers: { Authorization: `Bearer ${accessToken}` },
	};
	return axios
		.get(
			`${process.env.REACT_APP_CONSENT_AUTH_API_URI}/consent/user/${userName}/partner/${partnerId}/herds`,
			reqHeaders
		)
		.then((response: { data: { herds: string[] } }) => {
			return response.data.herds;
		});
};

export const writeConsent = (
	accessToken: string,
	userName: string,
	partnerId: string,
	selectedHerds: string[],
	consentedHerds: string[],
	setShowErrorMessage: (val: boolean) => void,
	continueAuth: () => void
) => {
	const reqHeaders = {
		headers: { Authorization: `Bearer ${accessToken}` },
	};
	const herdsToConsent = selectedHerds.filter(selectedHerd => !consentedHerds.includes(selectedHerd));
	const consentPayLoad = {
		UserName: userName,
		GranteeId: partnerId,
		ParticipantCodes: herdsToConsent
	};

	axios.post(
		`${process.env.REACT_APP_CONSENT_AUTH_API_URI}/consent/herds`,
		consentPayLoad,
		reqHeaders
	)
	.then(continueAuth)
	.catch((error) => {
		setShowErrorMessage(true);
		console.error(error);
	});
};
